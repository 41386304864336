import active from '../../assets/images/layout/statuses/active.svg';
import closed from '../../assets/images/layout/statuses/closed.svg';
import coolOff from '../../assets/images/layout/statuses/cool-off.svg';
import frozen from '../../assets/images/layout/statuses/frozen.svg';
import selfClosed from '../../assets/images/layout/statuses/self-closed.svg';
import selfExcluded from '../../assets/images/layout/statuses/self-excluded.svg';
import smallDone from '../../assets/images/layout/small-done.svg';
import smallClose from '../../assets/images/layout/small-close.svg';
import approved18 from '../../assets/images/layout/statuses/Approved-18.svg';
import closed18 from '../../assets/images/layout/statuses/Closed-18.svg';
import Blocked from '../../assets/images/layout/statuses/Blocked.svg';
import frozen18 from '../../assets/images/layout/statuses/Frozen-18.svg';

import i18n from "../../i18n";
import {SITE_BRAND, SITE_BRAND_CDN} from "../../constants/siteBrands";

const BASE_URL = process.env.REACT_APP_API_URL;
const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
export const ENV_MODE = process.env.REACT_APP_ENV_MODE || 'local'; // can be "local", "stage" or "prod"

const ENV_MODE_IMAGES = ENV_MODE === 'prod' ? 'prod' : 'stage'

/** DATA CENTER CONFIGURATION */
const DATACENTER = process.env.REACT_APP_DATACENTER || 'Localhost';

const DATACENTER_CODE_TYPE = {
    wiz_sp: 'wiz_sp',
    wiz_ir: 'wiz_ir',
    wiz_fk: 'wiz_fk',
    ds_ir: 'ds_ir', // dataswapp
    ds_fk: 'ds_fk', // dataswapp
    no_main: 'no_main',
    local: 'local',

};
const DATACENTER_CODE = process.env.REACT_APP_DATACENTER_CODE || DATACENTER_CODE_TYPE.local;

const isLocalMode = () => {
    return ENV_MODE === 'local';
}

const isStageMode = () => {
    return ENV_MODE === 'stage';
}

const isProdMode = () => {
    return ENV_MODE === 'prod';
}

const SIZE_PER_PAGE = 10;

const FIELD_TYPES = {
    image: 'image',
    text: 'text',
    html: 'html'
};

const DEFAULT_PAGE_SIZE = 10;

export const allPageSize = 'All';

const pageSizeOptions = [10, 20, 30, 50, 100, 500];

// libs with svg flags should be used
const FLAGS = [];

const ACCOUNT_STATUSES = {
    Lead: {
        icon: coolOff,
        text: 'lead',
        color: '#62A6E4'
    },
    Active: {
        icon: active,
        text: 'active',
        color: '#37A57E'
    },
    SelfClosed: {
        icon: selfClosed,
        text: 'selfclosed',
        color: '#62A6E4'
    },
    SelfExcluded: {
        icon: selfExcluded,
        text: 'selfexcluded',
        color: '#A2A2B9'
    },
    CoolOff: {
        icon: coolOff,
        text: 'cooloff',
        color: '#62A6E4'
    },
    Frozen: {
        icon: frozen,
        text: 'frozen',
        color: '#A2A2B9'
    },
    Closed: {
        icon: closed,
        text: 'closed',
        color: '#E44F73'
    }
};

const PLAYER_STATUSES = {
    Active: {
        icon: approved18,
        text: i18n.t("audit.approved"),
        color: '#37A57E'
    },
    SelfClosed:  {
        icon: closed,
        text: i18n.t("audit.selfClosed"),
        color: '#E44F73',
    },
    SoftBlock: {
        icon: frozen,
        text: 'Soft Block',
        color: '#A2A2B9'
    },
    Blocked : {
        icon: Blocked,
        text: 'Blocked',
        color: '#E44F73'
    },
    Frozen: {
        icon: frozen18,
        text: i18n.t("audit.frozen"),
        color: '#62A6E4'
    },
    Lead: {
        icon: coolOff,
        text: 'Lead',
        color: '#62A6E4'
    },
    Closed: {
        icon: closed,
        text: 'Closed',
        color: '#E44F73'
    },
    CompleteProfile : {
        icon: coolOff,
        text: 'Complete Profile',
        color: '#62A6E4'
    }
};


// TODO: This code will use in future
// const PLAYER_STATUSES = {
//     active: {
//         icon: active,
//         text: 'active',
//         color: '#37A57E'
//     },
//     closed: {
//         icon: closed,
//         text: 'closed',
//         color: '#E44F73'
//     },
//     coolOff: {
//         icon: coolOff,
//         text: 'cooloff',
//         color: '#62A6E4'
//     },
//     frozen: {
//         icon: frozen,
//         text: 'frozen',
//         color: '#A2A2B9'
//     },
//     selfClosed: {
//         icon: selfClosed,
//         text: 'selfclosed',
//         color: '#62A6E4'
//     },
//     selfExcluded: {
//         icon: selfExcluded,
//         text: 'selfexcluded',
//         color: '#A2A2B9'
//     }
// };

const TRANSACTION_STATUSES = {
    successful: {
        text: 'successful',
        color: '#37A57E',
        backgroundColor: '#DFF6EB'
    },
    processing: {
        text: 'processing',
        color: '#A2A2B9',
        backgroundColor: '#E6E6F2'
    },
    error: {
        text: 'error',
        color: '#E44F73',
        backgroundColor: '#FDDDE5'
    },
    pendingApproval: {
        text: 'pending approval',
        color: '#A2A2B9',
        backgroundColor: '#E6E6F2'
    }
};

const CONTACT_STATUSES = {
    verified: {
        text: 'verified',
        color: '#37A57E',
        icon: smallDone
    },
    notVerified: {
        text: 'not verified',
        color: '#A2A2B9',
        icon: smallClose
    }
};

export const getOptions = (options, label = '') => {
    if (Array.isArray(options)) {
        return options.map(key => ({
            value: key,
            content: label ? i18n.t(`${label}.${key}`) : key
        }))
    } else {
        return Object.keys(options).map(key => ({
            value: key,
            content: label ? i18n.t(`${label}.${key}`) : key
        }))
    }
};

const displayType = (type) => {
    if (type === 'ID - Front') {
        return 'Front'
    } else if (type === 'ID - Back') {
        return 'Back'
    } else {
        return type
    }
}

const fieldsForCentRestrictions = (fieldName) => {
    return fieldName.includes('MXN') ||
        fieldName.includes('EUR') ||
        fieldName.includes('USD')
}

const fieldsNamesForRestrict = (name) =>  name === 'amount' || name === 'depositAmount'

const COLLAPSE_OPTIONS = [
    'collapse',
    'expand'
]

const AGREEMENT_DOCUMENT_TYPES = [
    'SOF_Agreement',
    'SelfExclusion_Agreement',
    'AccountRecovery_Agreement',
];

const DOCUMENT_TYPES = [
    'SOF_Agreement',
    'PaymentConfirmation',
    'CURP_Certificate',
    'IFE',
    'INE',
    'Passport',
    'UtilityBill',
    'DrivingLicense',
    'DrivingLicenceFront',
    'DrivingLicenceBack',
    'IDFront',
    'IDBack',
    'ElectricityBill',
    'GasBill',
    'PhoneInternetBill',
    'BankCreditCardStatement',
    'CreditCardFront',
    'CreditCardBack',
    'DeclarationOfDeposits',
    'WaterBill',
    'HomePhoneBill',
    'BankStatement',
    'OtherRelevantBill',
    'PORCCHolder',
    'POIFrontCCHolder',
    'POIBackCCHolder',
    'JFormJointAccountForm',
    'Memorandum',
    'ArticlesOfAssociations',
    'CertificateOfRegisteredOffice',
    'CertificateOfShareholdersDirectors',
    'AuthorizedSignatory',
    'CompanyRegister',
    'CertificateOfIncorporation',
    'BoardOfDirectorsResolution',
    'FNSShareholdersDirectorsSignatories',
    'POIShareholdersDirectorsSignatories',
    'PORShareholdersDirectorsSignatories',
    'BankAccountTerminationLetter',
    'AuthorisationLetterForDeposit',
    'EWalletScreenshot',
    'BusinessConfirmationLetter',
    'BRLBankReferenceLetter',
    'TRLTaxReturnLetter',
    'PayslipOrSalaryConfirmation',
    'CCRCleanCriminalRecord',
    'JointBankAccountStatement',
    'JointTaxReturnLetter',
    'DOA',
    'BankDetails',
    'ProfessionalApplication',
    'DepositSelfie',
    'Other',
];

const DOCUMENT_TYPES_SIMPLE = [
    'Passport',
    'Driving License',
];

const DOCUMENT_SIDES = ['Front', 'Back', 'Both'];

const DOCUMENT_PAGE_MODES = {
    documentView: 'documentView',
    pendingDocuments: 'pendingDocuments'
};

const DOCUMENT_STATUSES_VALUES = [
    'Uploaded',
    'Approved',
    'Rejected',
    'CanceledByPlayer'
];

const PLAYER_ACCOUNT_TYPES = ['Personal', 'Joint', 'Corporate'];

const VIP_LEVEL = [0, 1, 2, 3, 4, 5, 6];

const VIP_LEVEL_OBJECT = {
    Bronze: 'Bronze',
    Silver: 'Silver',
    Gold: 'Gold',
    Platinum: 'Platinum',
    Diamond: 'Diamond',
    Elite: 'Elite',
}

const DEVICES = {
    Mobile: 'Mobile',
    Desktop: 'Desktop'
}

const EXPERIENCE_LEVEL = [
    'LowExperience',
    'Expert',
    'Unknown',
    'Rejected',
    'NoExperience',
    'HighExperience'
];

const IDENTIFICATION_TYPES_VALUES = [
    'Passport',
    'Driving License',
    'LocalIdentification'
];

const DOCUMENT_STATUSES = {
    approved: {
        text: "Approved",
        textTranslate: i18n.t("crm.document.status.Approved"),
        color: '#37A57E',
        backgroundColor: '#DFF6EB'
    },
    uploaded: {
        text: "Uploaded",
        textTranslate: i18n.t("crm.document.status.Uploaded"),
        color: '#62A6E4',
        backgroundColor: '#CFE8FF'
    },
    rejected: {
        text: "Rejected",
        textTranslate: i18n.t("crm.document.status.Rejected"),
        color: '#E44F73',
        backgroundColor: '#FDDDE5'
    },
    expired: {
        text: "Expired",
        textTranslate: i18n.t("crm.document.status.Expired"),
        color: '#E44F73',
        backgroundColor: '#FDDDE5'
    },
    canceledbyplayer: {
        text: "CanceledByPlayer",
        textTranslate: i18n.t("crm.document.status.CanceledByPlayer"),
        color: '#A2A2B9',
        backgroundColor: '#E6E6F2'
    }
};

const ACTIVITY_TABS = {
    comments: 'comments',
    history: 'history',
    meta: 'meta'
};

const BONUSES_TABS = {
    CASINO_BONUSES: 'CASINO_BONUSES',
    SPORT_BONUSES: 'SPORT_BONUSES',
};

const PDF_TYPES = ['application/pdf', 'image/pdf'];

const IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
const DOC_VIEW_STAGES = {
    view: 'view',
    initial: 'initial',
    handled: 'handled',
    selected: 'selected'
};

const SYSTEM_ENTITY = {
    Player: 'Player',
    Agent: 'Agent',
    System: 'System'
};

const DEFAULT_PASSWORD_LENGTH = 16;

const SITE_PERMISSION = {
    Promotion__Get_Promotions: 'Promotion__Get_Promotions',
    Promotion__Edit_Promotions: 'Promotion__Edit_Promotions',
    Player__Change_Password: 'Player__Change_Password',
    Chat__SendMessages: 'Chat__SendMessages',
    Chat__ViewMessages: 'Chat__ViewMessages',
    Transactions__Manual_Deposit: 'Transactions__Manual_Deposit',
    Transactions__Manual_Withdraw: 'Transactions__Manual_Withdraw',
    Transactions__View: 'Transactions__View',
    Transactions__WDs_Approval: 'Transactions__WDs_Approval',
    Transactions__WDs_Run_AutoApproval: 'Transactions__WDs_Run_AutoApproval',
    Transactions__WDs_WireTransfer_Completed: 'Transactions__WDs_WireTransfer_Completed',
    Transactions__WDs_RollBack: 'Transactions__WDs_RollBack',
    Transactions__Change_PendingForApproval: 'Transactions__Change_PendingForApproval',
    Transactions__Manual_Adjustments: 'Transactions__Manual_Adjustments',
    Transactions__SupportingDocuments: 'Transactions__SupportingDocuments',
    Transactions__Bulk_Player_Bonus_Update: 'Transactions__Bulk_Player_Bonus_Update',
    Transactions__Edit_Transaction_Owner: 'Transactions__Edit_Transaction_Owner',
    Player__View_Player_Info: 'Player__View_Player_Info',
    Player__Export_Player_Info: 'Player__Export_Player_Info',
    Player__Edit_Player_Info: 'Player__Edit_Player_Info',
    Player__Edit_Note: 'Player__Edit_Note',
    Player__View_Verification_Tab: 'Player__View_Verification_Tab',
    Player__Edit_Verification_Tab: 'Player__Edit_Verification_Tab',
    Player__View_Restrictions_Tab: 'Player__View_Restrictions_Tab',
    Player__Edit_Restrictions_Tab: 'Player__Edit_Restrictions_Tab',
    Player__View_Newsletters: 'Player__View_Newsletters',
    Player__Edit_Newsletters: 'Player__Edit_Newsletters',
    Player__View_Onfido_Tab: 'Player__View_Onfido_Tab',
    Player__Edit_Onfido_Tab: 'Player__Edit_Onfido_Tab',
    Player__View_Documents: 'Player__View_Documents',
    Player__Upload_Documents: 'Player__Upload_Documents',
    Player__Edit_Documents: 'Player__Edit_Documents',
    Player__View_Address: 'Player__View_Address',
    Player__Edit_Address: 'Player__Edit_Address',
    Player__View_Phone: 'Player__View_Phone',
    Player__Edit_Phone: 'Player__Edit_Phone',
    Player__View_Email: 'Player__View_Email',
    Player__Edit_Email: 'Player__Edit_Email',
    Player__Edit_Affiliate_Info: 'Player__Edit_Affiliate_Info',
    Player__View_Referral_Info: 'Player__View_Referral_Info',
    Player__Edit_Referral_Info: 'Player__Edit_Referral_Info',
    Player__Assign_Player: 'Player__Assign_Player',
    Player__Open_Gamification_Widget: 'Player__Open_Gamification_Widget',
    Player__View_Bets_Balance: 'Player__View_Bets_Balance',
    Players__Change_Player_VIP_Level: 'Players__Change_Player_VIP_Level',
    Player__Change_Agent_Owner: 'Player__Change_Agent_Owner',
    Players__Audit: 'Players__Audit',
    Player__Apply_Bonus: 'Player__Apply_Bonus',
    Player__Transactions__View: 'Player__Transactions__View',
    IT__Get_Roles: 'IT__Get_Roles',
    IT__Set_Roles: 'IT__Set_Roles',
    IT__Add_Role: 'IT__Add_Role',
    IT__Change_Agent_Role: 'IT__Change_Agent_Role',
    IT__Get_Brand_Configuration: 'IT__Get_Brand_Configuration',
    IT__Set_Brand_Configuration: 'IT__Set_Brand_Configuration',
    IT__Get_Brand_Tags: 'IT__Get_Brand_Tags',
    IT__Set_Agents: 'IT__Set_Agents',
    IT__Set_AgentBulk: 'IT__Set_AgentBulk',
    Agent__Edit_Password: 'Agent__Edit_Password',
    Game__View_Game_Categories: 'Game__View_Game_Categories',
    Game__Edit_Game_Categories: 'Game__Edit_Game_Categories',
    Game__View_Game_Site: 'Game__View_Game_Site',
    Game__Edit_Game_Site: 'Game__Edit_Game_Site',
    Game__View_DB_Game: 'Game__View_DB_Game',
    Game__Edit_DB_Game: 'Game__Edit_DB_Game',
    Game__Add_DB_Game: 'Game__Add_DB_Game',
    Game__Import_DB_Games: 'Game__Import_DB_Games',
    Game__Export_DB_Games: 'Game__Export_DB_Games',
    Player__Affiliate: 'Player__Affiliate',
    Agent__View_Info: 'Agent__View_Info',
    Agent__Register: 'Agent__Register',
    Dashboard__View: 'Dashboard__View',
    Filters__View: 'Filters__View',
    Filters__Edit: 'Filters__Edit',
    Retail__MX_View: 'Retail__MX_View',
    Retail__MX_Edit: 'Retail__MX_Edit',
    Retail__MX_Payment: 'Retail__MX_Payment',
    Retail__MX_Retail_KYC: 'Retail__MX_Retail_KYC',
    Retail__MX_Finance_A: 'Retail__MX_Finance_A',
    IT__View_Fraud_Restrictions: 'IT__View_Fraud_Restrictions',
    IT__Edit_Fraud_Restrictions: 'IT__Edit_Fraud_Restrictions',
    Cashier__Edit: 'Cashier__Edit',
    Cashier__View: 'Cashier__View',
    Retail__MX_Tax: 'Retail__MX_Tax',
    Player__Report__View: 'Player__Report__View',
    Player__Metabase__Statistics__View: 'Player__Metabase__Statistics__View',
    Game__Edit_Game_Details: 'Game__Edit_Game_Details',
    Transactions__Bulk_Transfer: 'Transactions__Bulk_Transfer'
};

const FINANCE_PERMISSIONS = {
    Transactions__View: 'Transactions__View',
    Transactions__WDs_Approval: 'Transactions__WDs_Approval',
    Transactions__Manual_Adjustments: 'Transactions__Manual_Adjustments',
    Transactions__SupportingDocuments: 'Transactions__SupportingDocuments',
    Transactions__Bulk_Player_Bonus_Update: 'Transactions__Bulk_Player_Bonus_Update',
}

const GENERAL_PERMISSIONS = {
    Filters__View: 'Filters__View',
    Filters__Edit: 'Filters__Edit',
    IT__Get_Brand_Configuration: 'IT__Get_Brand_Configuration',
    IT__Set_Brand_Configuration: 'IT__Set_Brand_Configuration',
    IT__Get_Brand_Tags: 'IT__Get_Brand_Tags',
    Game__View_Game_Categories: 'Game__View_Game_Categories',
    Player__Affiliate: 'Player__Affiliate',
    Promotion__Get_Promotions: 'Promotion__Get_Promotions',
    Promotion__Edit_Promotions: 'Promotion__Edit_Promotions',
    Dashboard__View: 'Dashboard__View',
    Agent__View_Info: 'Agent__View_Info',
    Player__Export_Player_Info: 'Player__Export_Player_Info',
}

const AGENT_PERMISSIONS = {
    IT__Get_Roles: 'IT__Get_Roles',
    IT__Set_Roles: 'IT__Set_Roles',
    IT__Set_Agents: 'IT__Set_Agents',
    IT__Add_Role: 'IT__Add_Role',
    IT__Change_Agent_Role: 'IT__Change_Agent_Role',
    Agent__Edit_Password: 'Agent__Edit_Password',
    Agent__Register: 'Agent__Register',
}

const RETAIL_PERMISSIONS = {
    Retail__MX_View: 'Retail__MX_View',
    Retail__MX_Edit: 'Retail__MX_Edit',
    Retail__MX_Payment: 'Retail__MX_Payment',
    Retail__MX_Retail_KYC: 'Retail__MX_Retail_KYC',
    Retail__MX_Finance_A: 'Retail__MX_Finance_A',
}

export {
    isLocalMode,
    isStageMode,
    isProdMode,
    displayType,
    fieldsForCentRestrictions,
    fieldsNamesForRestrict,
    COLLAPSE_OPTIONS,
    DEFAULT_PAGE_SIZE,
    pageSizeOptions,
    BASE_URL,
    GOOGLE_AUTH_CLIENT_ID,

    // Datacenter
    DATACENTER,
    DATACENTER_CODE_TYPE,
    DATACENTER_CODE,

    SIZE_PER_PAGE,
    FLAGS,
    FIELD_TYPES,
    PLAYER_STATUSES,
    TRANSACTION_STATUSES,
    CONTACT_STATUSES,
    AGREEMENT_DOCUMENT_TYPES,
    DOCUMENT_TYPES,
    DOCUMENT_TYPES_SIMPLE,
    DOCUMENT_SIDES,
    DOCUMENT_STATUSES_VALUES,
    DOCUMENT_PAGE_MODES,
    DOCUMENT_STATUSES,
    ACTIVITY_TABS,
    IMAGE_TYPES,
    PDF_TYPES,
    DOC_VIEW_STAGES,
    PLAYER_ACCOUNT_TYPES,
    SYSTEM_ENTITY,
    ACCOUNT_STATUSES,
    IDENTIFICATION_TYPES_VALUES,
    VIP_LEVEL,
    EXPERIENCE_LEVEL,
    DEFAULT_PASSWORD_LENGTH,
    SITE_PERMISSION,
    FINANCE_PERMISSIONS,
    GENERAL_PERMISSIONS,
    AGENT_PERMISSIONS,
    RETAIL_PERMISSIONS,
    BONUSES_TABS,
    VIP_LEVEL_OBJECT,
    DEVICES,
}

export const FIELD_TYPE = {
    STRING: 'string',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
    OBJECT: 'object',
    ARRAY: 'array',
}

export const MEXICO_BANKS = [
        {
            value: "0001",
            text: "ABC CAPITAL"
        },
        {
            value: "0002",
            text: "ACTINVER"
        },
        {
            value: "0003",
            text: "AFIRME"
        },
        {
            value: "0091",
            text: "ALTERNATIVOS"
        },
        {
            value: "0005",
            text: "ARCUS"
        },
        {
            value: "0006",
            text: "ASP INTEGRA OPC"
        },
        {
            value: "0007",
            text: "AUTOFIN"
        },
        {
            value: "0008",
            text: "AZTECA"
        },
        {
            value: "0022",
            text: "BABIEN"
        },
        {
            value: "0009",
            text: "BAJIO"
        },
        {
            value: "0010",
            text: "BANAMEX"
        },
        {
            value: "0011",
            text: "BANCO COVALTO"
        },
        {
            value: "0012",
            text: "BANCO S3"
        },
        {
            value: "0013",
            text: "BANCOMEXT"
        },
        {
            value: "0014",
            text: "BANCOPPEL"
        },
        {
            value: "0015",
            text: "BANCREA"
        },
        {
            value: "0016",
            text: "BANJERCITO"
        },
        {
            value: "0017",
            text: "BANK OF AMERICA"
        },
        {
            value: "0018",
            text: "BANKAOOL"
        },
        {
            value: "0019",
            text: "BANOBRAS"
        },
        {
            value: "0020",
            text: "BANORTE"
        },
        {
            value: "0021",
            text: "BANREGIO"
        },
        {
            value: "0023",
            text: "BANSI"
        },
        {
            value: "0024",
            text: "BANXICO"
        },
        {
            value: "0025",
            text: "BARCLAYS"
        },
        {
            value: "0026",
            text: "BBASE"
        },
        {
            value: "0027",
            text: "BBVA MEXICO"
        },
        {
            value: "0028",
            text: "BMONEX"
        },
        {
            value: "0029",
            text: "CAJA POP MEXICA"
        },
        {
            value: "0030",
            text: "CAJA TELEFONIST"
        },
        {
            value: "0031",
            text: "CB INTERCAM"
        },
        {
            value: "0032",
            text: "CI BOLSA"
        },
        {
            value: "0033",
            text: "CIBANCO"
        },
        {
            value: "0034",
            text: "COMPARTAMOS"
        },
        {
            value: "0035",
            text: "CONSUBANCO"
        },
        {
            value: "0036",
            text: "CREDICAPITAL"
        },
        {
            value: "0038",
            text: "CRISTOBAL COLON"
        },
        {
            value: "0039",
            text: "CoDi Valida"
        },
        {
            value: "0040",
            text: "DONDE"
        },
        {
            value: "0041",
            text: "EVERCORE"
        },
        {
            value: "0042",
            text: "FINAMEX"
        },
        {
            value: "0043",
            text: "FINCOMUN"
        },
        {
            value: "0044",
            text: "FOMPED"
        },
        {
            value: "0045",
            text: "FONDO (FIRA)"
        },
        {
            value: "0046",
            text: "GBM"
        },
        {
            value: "0047",
            text: "HIPOTECARIA FED"
        },
        {
            value: "0048",
            text: "HSBC"
        },
        {
            value: "0049",
            text: "ICBC"
        },
        {
            value: "0050",
            text: "INBURSA"
        },
        {
            value: "0051",
            text: "INDEVAL"
        },
        {
            value: "0052",
            text: "INMOBILIARIO"
        },
        {
            value: "0053",
            text: "INTERCAM BANCO"
        },
        {
            value: "0054",
            text: "INVERCAP"
        },
        {
            value: "0055",
            text: "INVEX"
        },
        {
            value: "0056",
            text: "JP MORGAN"
        },
        {
            value: "0057",
            text: "KUSPIT"
        },
        {
            value: "0058",
            text: "LIBERTAD"
        },
        {
            value: "0059",
            text: "MASARI"
        },
        {
            value: "0060",
            text: "MIFEL"
        },
        {
            value: "0061",
            text: "MIZUHO BANK"
        },
        {
            value: "0062",
            text: "MONEXCB"
        },
        {
            value: "0063",
            text: "MUFG"
        },
        {
            value: "0064",
            text: "MULTIVA BANCO"
        },
        {
            value: "0066",
            text: "NAFIN"
        },
        {
            value: "0004",
            text: "NU MEXICO"
        },
        {
            value: "0075",
            text: "OPM"
        },
        {
            value: "0067",
            text: "PAGATODO"
        },
        {
            value: "0068",
            text: "PROFUTURO"
        },
        {
            value: "0069",
            text: "REFORMA"
        },
        {
            value: "0070",
            text: "SABADELL"
        },
        {
            value: "0071",
            text: "SANTANDER"
        },
        {
            value: "0072",
            text: "SCOTIABANK"
        },
        {
            value: "0073",
            text: "SHINHAN"
        },
        {
            value: "0074",
            text: "STP"
        },
        {
            value: "0090",
            text: "TESORED"
        },
        {
            value: "0076",
            text: "UNAGRA"
        },
        {
            value: "0077",
            text: "VALMEX"
        },
        {
            value: "0078",
            text: "VALUE"
        },
        {
            value: "0079",
            text: "VE POR MAS"
        },
        {
            value: "0080",
            text: "VECTOR"
        },
        {
            value: "0081",
            text: "VOLKSWAGEN"
        },
        {
            value: "",
            text: "Otro"
        }
    ]


export const BANK_CODES = [
    {
        value: '002',
        text: 'BANAMEX'
    },
    {
        value: '006',
        text: 'BANCOMEXT'
    },
    {
        value: '009',
        text: 'BANOBRAS'
    },
    {
        value: '012',
        text: 'BBVA BANCOMER'
    },
    {
        value: '014',
        text: 'SANTANDER'
    },
    {
        value: '019',
        text: 'BANJERCITO'
    },
    {
        value: '021',
        text: 'HSBC'
    },
    {
        value: '030',
        text: 'BAJÍO'
    },
    {
        value: '036',
        text: 'INBURSA'
    },
    {
        value: '042',
        text: 'MIFEL'
    },
    {
        value: '044',
        text: 'SCOTIABANK'
    },
    {
        value: '058',
        text: 'BANREGIO'
    },
    {
        value: '059',
        text: 'INVEX'
    },
    {
        value: '060',
        text: 'BANSI'
    },
    {
        value: '062',
        text: 'AFIRME'
    },
    {
        value: '072',
        text: 'BANORTE'
    },
    {
        value: '106',
        text: 'BANK OF AMERICA'
    },
    {
        value: '108',
        text: 'MUFG'
    },
    {
        value: '110',
        text: 'JP MORGAN'
    },
    {
        value: '112',
        text: 'BMONEX'
    },
    {
        value: '113',
        text: 'VE POR MAS'
    },
    {
        value: '126',
        text: 'CREDIT SUISSE'
    },
    {
        value: '127',
        text: 'AZTECA'
    },
    {
        value: '128',
        text: 'AUTOFIN'
    },
    {
        value: '129',
        text: 'BARCLAYS'
    },
    {
        value: '130',
        text: 'COMPARTAMOS'
    },
    {
        value: '132',
        text: 'MULTIVA BANCO'
    },
    {
        value: '133',
        text: 'ACTINVER'
    },
    {
        value: '135',
        text: 'NAFIN'
    },
    {
        value: '136',
        text: 'INTERCAM BANCO'
    },
    {
        value: '137',
        text: 'BANCOPPEL'
    },
    {
        value: '138',
        text: 'ABC CAPITAL'
    },
    {
        value: '140',
        text: 'CONSUBANCO'
    },
    {
        value: '141',
        text: 'VOLKSWAGEN'
    },
    {
        value: '143',
        text: 'CIBanco'
    },
    {
        value: '145',
        text: 'BBASE'
    },
    {
        value: '147',
        text: 'BANKAOOL'
    },
    {
        value: '148',
        text: 'PagaTodo'
    },
    {
        value: '150',
        text: 'INMOBILIARIO'
    },
    {
        value: '151',
        text: 'Donde'
    },
    {
        value: '152',
        text: 'BANCREA'
    },
    {
        value: '154',
        text: 'BANCO COVALTO'
    },
    {
        value: '155',
        text: 'ICBC'
    },
    {
        value: '156',
        text: 'SABADELL'
    },
    {
        value: '157',
        text: 'SHINHAN'
    },
    {
        value: '158',
        text: 'MIZUHO BANK'
    },
    {
        value: '159',
        text: 'BANK OF CHINA'
    },
    {
        value: '160',
        text: 'BANCO S3'
    },
    {
        value: '166',
        text: 'Banco del Bienestar'
    },
    {
        value: '168',
        text: 'HIPOTECARIA FEDERAL'
    },
    {
        value: '600',
        text: 'MONEXCB'
    },
    {
        value: '601',
        text: 'GBM'
    },
    {
        value: '602',
        text: 'MASARI CB'
    },
    {
        value: '605',
        text: 'VALUÉ'
    },
    {
        value: '608',
        text: 'VECTOR'
    },
    {
        value: '610',
        text: 'B&B'
    },
    {
        value: '613',
        text: 'MULTIVA CBOLSA'
    },
    {
        value: '616',
        text: 'FINAMEX'
    },
    {
        value: '617',
        text: 'VALMEX'
    },
    {
        value: '618',
        text: 'ÚNICA'
    },
    {
        value: '619',
        text: 'MAPFRE'
    },
    {
        value: '620',
        text: 'PROFUTURO'
    },
    {
        value: '621',
        text: 'CB ACTINBER'
    },
    {
        value: '622',
        text: 'OACTIN'
    },
    {
        value: '623',
        text: 'SKANDIA'
    },
    {
        value: '626',
        text: 'CBDEUTSCHE'
    },
    {
        value: '627',
        text: 'ZURICH'
    },
    {
        value: '628',
        text: 'ZURICHVI'
    },
    {
        value: '629',
        text: 'SU CASITA'
    },
    {
        value: '630',
        text: 'C.B. INTERCAM'
    },
    {
        value: '631',
        text: 'C.I. BOLSA'
    },
    {
        value: '632',
        text: 'BULLTICK C.B.'
    },
    {
        value: '633',
        text: 'STERLING'
    },
    {
        value: '634',
        text: 'FINCOMUN'
    },
    {
        value: '636',
        text: 'HDI SEGUROS'
    },
    {
        value: '637',
        text: 'ORDER'
    },
    {
        value: '638',
        text: 'AKALA'
    },
    {
        value: '640',
        text: 'C.B. JP MORGAN'
    },
    {
        value: '642',
        text: 'REFORMA'
    },
    {
        value: '646',
        text: 'STP'
    },
    {
        value: '647',
        text: 'TELECOMM'
    },
    {
        value: '648',
        text: 'EVERCORE'
    },
    {
        value: '649',
        text: 'SKANDIA'
    },
    {
        value: '651',
        text: 'SEGMTY'
    },
    {
        value: '652',
        text: 'ASEA'
    },
    {
        value: '653',
        text: 'KUSPIT'
    },
    {
        value: '655',
        text: 'SOFIEXPRESS'
    },
    {
        value: '656',
        text: 'UNAGRA'
    },
    {
        value: '659',
        text: 'OPCIONES EMPRESARIALES DEL NOROESTE'
    },
    {
        value: '670',
        text: 'LIBERTAD'
    },
    {
        value: '674',
        text: 'AXA'
    },
    {
        value: '677',
        text: 'CAJA POP MEXICA'
    },
    {
        value: '679',
        text: 'FND'
    },
    {
        value: '684',
        text: 'TRANSFER'
    },
    {
        value: '901',
        text: 'CLS'
    },
    {
        value: '902',
        text: 'INDEVAL'
    }
]

export const validateClabe = (clabe) => {
    // Ensure the length is correct
    if (clabe.length !== 18) return false;

    // Extract components
    const controlDigit = parseInt(clabe[17], 10);

    // Calculation of the control digit
    const factors = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];
    let sum = 0;
    for (let i = 0; i < 17; i++) {
        sum += (parseInt(clabe[i], 10) * factors[i]) % 10;
    }

    const calculatedControlDigit = (10 - (sum % 10)) % 10;

    return controlDigit === calculatedControlDigit;
}

export const renderGameImg = (brand, name) => {
    return [
        {
            name: 'Mobile', url: `${SITE_BRAND_CDN[brand]}${ENV_MODE_IMAGES}/mobile/${name}.png`
        },
        {
            name: 'Desktop', url: `${SITE_BRAND_CDN[brand]}${ENV_MODE_IMAGES}/desktop/${name}.png`
        },
        {
            name: 'Animated', url: `${SITE_BRAND_CDN[brand]}${ENV_MODE_IMAGES}/animated/${name}.png`
        },
        {
            name: 'Mobile Large', url: `${SITE_BRAND_CDN[brand]}${ENV_MODE_IMAGES}/mobileLarge/${name}.png`
        },
        {
            name: 'Desktop Large', url: `${SITE_BRAND_CDN[brand]}${ENV_MODE_IMAGES}/desktopLarge/${name}.png`
        }
    ]
}


export const arrayOfDisplayedStatuses = [
    'Closed',
    'SelfClosed',
    'Frozen',
    'SoftBlock',
    'Blocked',
    'Lead',
    'CompleteProfile'
];